import React from 'react';
import _debounce from 'lodash.debounce';
import { createClient } from 'contentful';
import { Link } from 'gatsby';


import HeaderSecondary from '../_global/HeaderSecondary.jsx';
import Banner from '../_global/Banner.jsx';
import MainLayout from '../layouts/MainLayout.jsx';

import './BlogPage.scss';

const client = createClient({
    space: process.env.GATSBY_CONTENTFUL_SPACE_ID,
    accessToken: process.env.GATSBY_CONTENTFUL_ACCESS_TOKEN,
    //host: process.env.GATSBY_CONTENTFUL_HOST
});

class BlogPage extends React.Component {

    state = {
        query: '',
        posts: [],
        page: 1,
        underFold: false,
        isQuerying: true,
        moreToLoad: true
    };

    debouncedSearch = _debounce(() => {

        this.setState({
            isQuerying: true
        }, () => {

            const limit = 50;
            const skip = limit * (this.state.page - 1);

            client.getEntries({
                limit,
                skip,
                content_type: 'blogPost',
                order: '-sys.createdAt',
                query: this.state.query,
            }).then((response) => {

                this.setState({
                    posts: this.state.page === 1 ? response.items : this.state.posts.concat(response.items),
                    isQuerying: false,
                    moreToLoad: this.state.posts.length + response.items.length < response.total
                });

            }).catch(console.error);

        });

    }, 150);

    componentDidMount() {

        this.debouncedSearch();
    }

    search = () => {

        this.setState({ posts: [], page: 1 }, this.debouncedSearch);
    };

    onChangeQuery = ({ currentTarget: { value: query } }) => {

        this.setState({ query }, this.search);
    };

    onClearQuery = () => {

        this.setState({ query: '' }, this.search);
    };

    onLoadMore = () => {

        this.setState({ page: this.state.page + 1 }, this.debouncedSearch);
    };

    get posts() {

        return this.state.posts.map(({fields: post}) => (
            <div key={post.slug} className="blog-post-summary">
                <div className="panel panel-default">
                    <div className="panel-body">
                        <div className="media">
                            <div className="media-left hidden-xs hidden-sm">
                                <Link to={`/blog/${post.slug}`}>
                                    <div className="media-object" style={post.heroImage ? {backgroundImage: `url(${post.heroImage.fields.file.url})`} : null} />
                                </Link>
                            </div>
                            <div className="media-body">
                                <Link to={`/blog/${post.slug}`}>
                                    <h3 className="media-heading">{post.title}</h3>
                                </Link>
                                {post.description}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ));
    }

    render() {
        return (
            <MainLayout>
                <div className="blog-page">

                    <HeaderSecondary />

                    <Banner>
                        <div className={`blog-search ${this.state.query ? 'active' : ''}`}>

                            <input
                                className="form-control"
                                type="search"
                                value={this.state.query}
                                onChange={this.onChangeQuery}
                                placeholder="Blog search"
                            />
                            <button type="button" value="" onClick={this.onChangeQuery}>x</button>
                        </div>
                    </Banner>

                    <div className="container">

                        <div className="row">

                            <div className="col-lg-8 col-lg-offset-2 col-md-10 col-md-offset-1">

                                {this.posts}

                            </div>
                        </div>

                        <div className="row">

                            <div className="col-lg-4 col-lg-offset-4 col-md-6 col-md-offset-3">

                                <div className="load-more">
                                    <button
                                        className={`btn btn-block btn-success ${this.state.moreToLoad ? '' : ' hidden'}`}
                                        onClick={this.onLoadMore}
                                        disabled={this.state.isQuerying}
                                    >
                                        {this.state.isQuerying ? 'Loading...' : 'Load more'}
                                    </button>
                                </div>

                            </div>

                        </div>
                    </div>

                </div>

            </MainLayout>
        );
    }
}

export default BlogPage;